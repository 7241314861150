import React from "react";
import ItemGrid from "../../components/common/ItemGrid";
import Frame from "../../components/frame/Frame";
import { ENDPOINTS, MENYVELGEREN_MODULES } from "../../constants";
import { useSelector } from "react-redux";
import {
  selectMenyvelgerenLoading,
  selectMenyvelgerenMenus,
} from "../../redux/menyvelgerenDataSlice";
import { getMenuGroupById } from "../../services";
import { useNavigate, useParams } from "react-router-dom";

const MenuGroupView = () => {
  const navigate = useNavigate();
  const menugroup = useParams().group;
  const menuData = useSelector(selectMenyvelgerenMenus);
  const isMenuLoadingLoading = useSelector(selectMenyvelgerenLoading);

  const items = (menuData || [])
    .filter((a) => a.menugroup === menugroup)
    .map((p) => ({
      text: p.title,
      linkTo: MENYVELGEREN_MODULES.PRODUCTLIST(p.mid),
      image: ENDPOINTS.image(p.picture),
      icon: getMenuGroupById(p.menugroup).icon,
      useImage: getMenuGroupById(p.menugroup).useImage,
      useImage: true,
    }));

  return (
    <Frame
      useBasketFooter={true}
      enableClickGuard={true}
      enablePadding={true}
      loading={isMenuLoadingLoading}
    >
      <ItemGrid
        items={items}
        onPreviousPageClick={() => navigate(MENYVELGEREN_MODULES.MENU())}
      ></ItemGrid>
    </Frame>
  );
};

export default MenuGroupView;
